import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  var $h = _ref.$h;

  var getHtmlTitle = function getHtmlTitle() {
    return $h(_templateObject || (_templateObject = _taggedTemplateLiteral(["r&sup2;logo Reference"])));
  };

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title">          
          <div class="title-text text-color-gray" innerHTML=${getHtmlTitle()}></div>
        </div>
        <div class="right">
          <a class="link icon-only external" title="r²logo app" href="https://www.r2logo.com/app" target="_blank">
            <img alt="" src="./static/icons/128x128.png" height="32" width="32" class="icon" />
          </a>
        </div>
      </div>
    </div>
    <!-- Toolbar-->
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <a href="/responsive-1/" class="link"><i class="icon icon-prev"/>The Responsive Robot</a>
        <a href="/setup/" class="link">Setup Tips<i class="icon icon-next"/></a>
      </div>
    </div>

    <!-- Scrollable page content-->
    <div class="page-content">
      <div class="block">
        <div class="row bg-color-blue">
          <div class="col-30">Command</div><div class="col-70">Action</div>
        </div>
        <div class="row">
          <div class="col-30">ask 'question'</div><div class="col-70">The question inside the quotes is displayed in a speach balloon and a text entry panel is displayed. 
           The program waits (with the clock running) until someone types an answer and taps SEND. The text entered is available in the @answer datum.</div>
        </div>
        <div class="row">
          <div class="col-30">backward n<br/>back n<br/>bk n</div><div class="col-70">Move BACKWARD n steps</div>
        </div>
        <div class="row">
          <div class="col-30">break</div><div class="col-70">Use inside a loop to end the loop immediately</div>
        </div>
        <div class="row">
          <div class="col-30">clearscreen<br/>clear<br/>cls</div><div class="col-70">Erase all drawing &amp; text</div>
        </div>

        <div class="row">
          <div class="col-30">color name</div><div class="col-70">Set the robot's drawing pen to the named color such as <i>white, black, blue, green pink</i> and so on.</div>
        </div>
        <div class="row">
          <div class="col-30">color next</div><div class="col-70">Set the robot's drawing pen to the next color in the rainbow color wheel. There are 9 rainbow colors and the pen always starts at <i>blue</i>.</div>
        </div>
        <div class="row">
          <div class="col-30">dance</div><div class="col-70">Set the robot's movement to dance steps</div>
        </div>
        <div class="row">
          <div class="col-30">exit</div><div class="col-70">End the program. Usually used with the ON command.</div>
        </div>
        <div class="row">
          <div class="col-30">face name</div><div class="col-70">Change the robot's face. Face names always include robot, dog and princess. More faces may be added.</div>
        </div>
        <div class="row">
          <div class="col-30">forward n<br/>fwd n</div><div class="col-70">Move FORWARD n steps</div>
        </div>
        <div class="row">
          <div class="col-30">head n</div> <div class="col-70">Set the robot's heading to n degrees from 0 to 360.</div>
        </div>
        <div class="row">
          <div class="col-30">hide</div><div class="col-70">Make the robot invisible.</div>
        </div>
        <div class="row">
          <div class="col-30">home</div> <div class="col-70">Send the robot to the center of the playground.</div>
        </div>
        <div class="row">
          <div class="col-30">if (expression)<br/>...<br/>else<br/>...<br/> end</div><div class="col-70">Evaluate the expression in parenthesis. If the result is TRUE, run the commands up to the ELSE (if given) or END (if no ELSE).
            If the result is FALSE, run the commands after the ELSE (if given) or skip to END (if no ELSE).</div>
        </div>
        <div class="row">
          <div class="col-30">jump vpos hpos</div> <div class="col-70">Jump the robot to one of nine relative positions on the visible screen. vpos may be: top, middle or bottom. hpos may be: left middle or right. </div>
        </div>
        <div class="row">
          <div class="col-30">left n</div><div class="col-70">Turn LEFT n degrees</div>
        </div>
        <div class="row">
          <div class="col-30">make @name val</div> <div class="col-70">Create a new dataum called name with an initial value of val.</div>
        </div>
        <div class="row">
          <div class="col-30">move x y</div> <div class="col-70">Move the robot to position x pixels from the left and y pixels from the top of the playground. The top left corner of the playground is x=0, y=0.
            Remember that the playground may be much larger than the screen depending on the zoom setting.</div>
        </div>
        <div class="row">
          <div class="col-30">on 'c' ... end</div><div class="col-70">Whenever the key for the given letter is hit, the robot will run the commands up to the next <i>end</i> command, then return to what it was doing.
              The green and blue control pads on the main panel can be used instead of a keyboard. Tap the main panel to show/hide them. The green pad has numbers 1-4 and the blue pad has letters A-D.</div>
        </div>
        <div class="row">
          <div class="col-30">pen up/down</div><div class="col-70">Put the robot's pen down to draw as it moves and set the pen up to move without drawing.</div>
        </div>
        <div class="row">
          <div class="col-30">print 'message'</div><div class="col-70">The message inside the quotes are printed to the Program Output box.</div>
        </div>
        <div class="row">
          <div class="col-30">repeat n ... end</div><div class="col-70">Repeat the commands up to the next <i>end</i> command n times.</div>
        </div>
        <div class="row">
          <div class="col-30">return</div><div class="col-70">Exit from an action (TO or ON) immediately and return to the caller.</div>
        </div>
        <div class="row">
          <div class="col-30">right n</div><div class="col-70">Turn RIGHT n degrees.</div>
        </div>
        <div class="row">
          <div class="col-30">run</div><div class="col-70">Set the robot's movement to a fast run.</div>
        </div>
        <div class="row">
          <div class="col-30">say 'message'</div><div class="col-70">The message inside the quotes is displayed in a speach balloon. The balloon will disappear the next time the robot moves. </div>
        </div>
        <div class="row">
          <div class="col-30">show</div><div class="col-70">Make the robot visible.</div>
        </div>
        <div class="row">
          <div class="col-30">speed n</div> <div class="col-70">Set the clock speed for the robot from 1 (normal) to 10 (warp).</div>
        </div>
        <div class="row">
          <div class="col-30">stride n</div> <div class="col-70">Set the number of pixels per robot step to n. The normal stride length is 5.</div>
        </div>
        <div class="row">
          <div class="col-30">text n</div><div class="col-70">Set the text size for the WRITE command to n points. </div>
        </div>
        <div class="row">
          <div class="col-30">to 'action' ... end</div><div class="col-70">Create a new command named 'action'. Whenever the new command is used, run the commands up to the next <i>end</i> command</div>
        </div>
        <div class="row">
          <div class="col-30">walk</div><div class="col-70">Set the robot's movement to a slow walk.</div>
        </div>
        <div class="row">
          <div class="col-30">width n</div><div class="col-70">Set the width of the robot's drawing pen to n points.</div>
        </div>
        <div class="row">
          <div class="col-30">write 'message'</div><div class="col-70">The message inside the quotes is written on the Playground in the active color and TEXT size.</div>
        </div>
      </div>
            <div class="block">
              <span style="font-size: 7pt;">©2021 maxamind, llc</span>
            </div>

    </div>
  </div>
`
    }
    ;
}

framework7Component.id = 'a8fe0fa98b';
export default framework7Component;