
import HomePage from '../pages/home.f7.html';
import AboutPage from '../pages/about.f7.html';

import GetStartedPage from '../pages/getting-started.f7.html'; 
import ReferencePage from '../pages/reference.f7.html'; 
import Language1Page from '../pages/language-1.f7.html'; 
import Responsive1Page from '../pages/responsive-1.f7.html'; 
import SetupPage from '../pages/setup.f7.html'; 

import NotFoundPage from '../pages/404.f7.html';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/get-started/',
    component: GetStartedPage,
  },
  {
    path: '/reference/',
    component: ReferencePage,
  },
  {
    path: '/language-1/',
    component: Language1Page,
  },
  {
    path: '/responsive-1/',
    component: Responsive1Page,
  },
  {
    path: '/setup/',
    component: SetupPage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;