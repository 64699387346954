import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  _objectDestructuringEmpty(_ref);

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title">          
          <div class="title-text">Welcome to r²logo!</div>
          <div class="sub-title-text">A fun place to explore programming through graphics, storytelling and game creation</div>
        </div>
        <div class="right">
          <a class="link icon-only external" title="r²logo app" href="https://www.r2logo.com/app" target="_blank">
            <img alt="" src="./static/icons/128x128.png" height="32" width="32" class="icon" />
          </a>
        </div>
      </div>
    </div>
    <!-- Toolbar-->
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <a href="#" class="link"></a>
        <a href="/get-started/" class="link">Getting Started<i class="icon icon-next"/></a>
      </div>
    </div>

    <!-- Scrollable page content-->
    <div class="page-content">
      <div class="block-title"></div>
      <div class="block">
        
        <img src="./static/hello-world-900x200.png" style="width: 100%;" />
<p><span style="font-size: 12pt;">This is the home of the r²logo programming language. r²logo is a modern, web-powered version of the classic Logo language developed in the 1960s. The turtle icon has been replaced by a robot - but it can still look like a turtle if you like. We call it <strong>r²</strong></span><span style="font-size: 12pt;"> because it's a <b>r</b>esponsive <b>r</b>obot - it can see shapes in its playground and you can program it to respond to events its world.  </span></p>
<p><span style="font-size: 12pt;"><strong>r²logo is free for everyone!</strong> You can run the app in most any browser on most any device by simply opening <a class="external" title="r²logo app" href="https://www.r2logo.com/app" target="_blank">r2logo.com/app</a> or just click the robot icon up there on the right. No special installation is needed, but most tablets and desktops will let you save the link as a web app or shortcut that you can later run offline. The app will adapt to the available screen size, so tablets, laptops, and desktops all work great. If you need some tips for your device, take a look at Setup Tips for Popular Devices.  </span></p>
<p><span style="font-size: 12pt;">Once you have the app open on your device, move on to <a href="/get-started/">Getting Started</a> for a quick overview or just try some of the sample programs in the Faves tab on the right side panel.</span></p>
<p> </p>
      </div>

      <div class="block">
        <span style="font-size: 7pt;">©2021 maxamind, llc</span>
      </div>


    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '9ac8ee61be';
export default framework7Component;