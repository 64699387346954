import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";

/** @jsx $jsx */
import { $jsx } from 'framework7';
export default (function (props, _ref) {
  _objectDestructuringEmpty(_ref);

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div id="app">


    <!-- Left panel with cover effect -->
    <div class="panel panel-left panel-cover panel-init" data-visible-breakpoint="960">
      <div class="view view-init" data-name="left">
        <div class="page">
            <div class="navbar">
              <div class="navbar-bg"></div>
              <div class="navbar-inner sliding">

               <form class="searchbar" id="searchbar-autocomplete">
                  <div class="searchbar-inner">
                    <div class="searchbar-input-wrap">
                      <input type="search" placeholder="Search" />
                      <i class="searchbar-icon"></i>
                      <span class="input-clear-button"></span>
                    </div>
                    <span class="searchbar-disable-button">Cancel</span>
                  </div>
                </form> 

              </div>
            </div>          
          <div class="page-content">
            <div class="block-title">Table of Contents</div>
            <div class="list simple-list">
              <ul>
                <li><a href="/" data-view=".view-main" class="link panel-close">Home - Welcome to r²logo</a></li>
                <li><a href="/get-started/" data-view=".view-main" class="link panel-close">Getting Started</a>
<!--                  <ul>
                    <li><a href="/get-started/#r2logo_playground" data-view=".view-main" class="panel-close">The r2logo Playground </a></li>
                    <li><a href="/get-started/#r2_robot" data-view=".view-main" class="panel-close">Meet the r2 Robot</a></li>
                    <li><a href="/get-started/#program_controls" data-view=".view-main" class="panel-close">Program Controls</a></li>
                    <li><a href="/get-started/#your_first_program" data-view=".view-main" class="panel-close">Your First Program</a></li>
                  </ul> -->
                </li>
                <li><a href="/language-1/" data-view=".view-main" class="link panel-close">The r²logo Language</a></li>
                <li><a href="/responsive-1/" data-view=".view-main" class="link panel-close">The Responsive Robot</a></li>
                <li><a href="/reference/" data-view=".view-main" class="link panel-close">r²logo Reference</a></li>
                <li><a href="/setup/" data-view=".view-main" class="link panel-close">Setup Tips for Popular Devices</a></li>
              </ul>
            </div>
            <div class="block-title">Resouces</div>
            <div class="block">
              <ul>
                <li><a href="https://en.wikipedia.org/wiki/Logo_(programming_language)" data-view=".view-main" class="link external panel-close">Logo Language & History (wikipedia)</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right panel with reveal effect-->
    <div class="panel panel-right panel-reveal theme-dark">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Right Panel</div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">Right panel content goes here</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Your main view, should have "view-main" class -->
    <div class="view view-main view-init safe-areas" data-url="/"></div>


    <!-- Popup -->
    <div class="popup" id="my-popup">
      <div class="view">
        <div class="page">
          <div class="navbar">
            <div class="navbar-bg"></div>
            <div class="navbar-inner">
              <div class="title">Popup</div>
              <div class="right">
                <a href="#" class="link popup-close">Close</a>
              </div>
            </div>
          </div>
          <div class="page-content">
            <div class="block">
              <p>Popup content goes here.</p>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
`
    }
    ;
});