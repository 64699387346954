import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  _objectDestructuringEmpty(_ref);

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title">          
          <div class="title-text">Getting Started with r²logo</div>
        </div>
        <div class="right">
          <a class="link icon-only external" title="r²logo app" href="https://www.r2logo.com/app" target="_blank">
            <img alt="" src="./static/icons/128x128.png" height="32" width="32" class="icon" />
          </a>
        </div>
      </div>
    </div>
    <!-- Toolbar-->
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <a href="/" class="link"><i class="icon icon-prev"/>Home</a>
        <a href="/language-1/" class="link">The r²logo Language<i class="icon icon-next"/></a>
      </div>
    </div>

    <!-- Scrollable page content-->
    <div class="page-content">
      <div class="block-title"></div>
      <div class="block">
				<p>Here's a quick overview and some basics to help you get started writing programs for the bot.</p>
<p>When you run the playground app on a laptop or desktop with a wide screen, you will see all the panels open at once like this:</p>
<p><img style="border: 1px solid #333333; display: block; margin-left: auto; margin-right: auto; border-width: 1px;" src="./static/screen-shot-1.png" width="100%" /></p>
<table style="height: 13px; width: 903px;">
<tbody>
<tr>
<td style="width: 199px; text-align: center;"><strong>Code Panel</strong></td>
<td style="text-align: center;"><strong>Playground Panel</strong></td>
<td style="width: 200px; text-align: center;"><strong>Tools Panel</strong></td>
</tr>
<tr>
<td style="width: 199px;">The Code Editor, Program Output and file controls are on the left panel</td>
<td>The main panel is the view into the playground. You can start, pause and stop your program, zoom in or out on the view and change backgrounds</td>
<td style="width: 200px;">The right panel has tabs for the Help, Faves and the program Monitor</td>
</tr>
</tbody>
</table>
<h3 id="r2logo_playground">The r²logo playground</h3>
<p>The flat world that the robot lives on is called the playground. It's actually around 4 times wider and 4 times taller than the screen window that you can see. So if the bot moves beyond the edges, you can just scroll around to see more. </p>
<p> </p>
<p> </p>
<h4>More Details</h4>
<p>The left, top corner of the playground is position x = 0, y = 0 or simply 0, 0. So x increases toward the right and y increases down the screen.</p>
<h3 id="r2_robot">The r² robot</h3>
<p>The r² robot is a virtual robot that lives on the flat playground. It can perform movements, draw with colored pens and scan around it to detect objects on the playground. r² can do lots of things, but it doesn't do anything without instructions from you - the programmer. Your commands control the bot's movement, drawing, writing, speech bubbles, math solutions - everything it does.</p>
<h3 id="program_controls">The program controls</h3>
<p>As the programmer, you use the controls along the bottom of your view to control your view of the playground, work with saved programs and run them.</p>
<p> </p>
<p><img style="display: block; margin-left: auto; margin-right: auto;" src="./static/screen-shot-2.png" width="100%" /></p>
<p> </p>
<p>The four buttons to the left are at the bottom of the left panel and may only be visible if the left panel is open. These buttons control the program editor:</p>
<p style="padding-left: 30px;">New Doc - clear the editor for new code</p>
<p style="padding-left: 30px;">Get Doc - get a saved doc from a file either on the device or cloud storage</p>
<p style="padding-left: 30px;">Save Doc - save the program editor to a file either on the device or cloud storage</p>
<p style="padding-left: 30px;">Add to Faves - save the program editor to my personal faves. NOTE: the browser may delete these without warning</p>
<p> </p>
<h3 id="your_first_program">Run your first program</h3>
<p>Let's start with a simple greeting. Clear the program editor and type:</p>
<p style="padding-left: 30px;"><code>say "Hello, world!"</code></p>
<p>Now tap the run button and see what happens. If the robot understands your program, it will print OK in the Program Output box and will start doing your commands. If there's a problem understanding your program, it will print a message in the program Output box to tell you.</p>
<h3>EXPLORE!</h3>
<p>There is much more to learn about programming our virtual bots. So explore! </p>





      </div>
      <div class="block">
        <div class="row">
          <div class="col-30"><a href="/" class="link">Previous Article</a></div>
          <div class="col-30"></div>
          <div class="col-30 text-align-right"><a href="/get-started/" class="link">Next Article</a></div>
        </div>
      </div>

            <div class="block">
              <span style="font-size: 7pt;">©2021 maxamind, llc</span>
            </div>


    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '589fb2c696';
export default framework7Component;