import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";

/** @jsx $jsx */
import { $jsx } from 'framework7';

function framework7Component(props, _ref) {
  _objectDestructuringEmpty(_ref);

  return function ($ctx) {
      var $ = $ctx.$;
      var $h = $ctx.$h;
      var $root = $ctx.$root;
      var $f7 = $ctx.$f7;
      var $f7route = $ctx.$f7route;
      var $f7router = $ctx.$f7router;
      var $theme = $ctx.$theme;
      var $update = $ctx.$update;
      var $store = $ctx.$store;

      return $h`
  <div class="page" data-name="home">
    <!-- Top Navbar -->
    <div class="navbar">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a href="#" class="link icon-only panel-open" data-panel="left">
            <i class="icon f7-icons if-not-md">menu</i>
            <i class="icon material-icons if-md">menu</i>
          </a>
        </div>
        <div class="title">          
          <div class="title-text text-color-gray">The r²logo Language</div>
        </div>
        <div class="right">
          <a class="link icon-only external" title="r²logo app" href="https://www.r2logo.com/app" target="_blank">
            <img alt="" src="./static/icons/128x128.png" height="32" width="32" class="icon" />
          </a>
        </div>
      </div>
    </div>
    <!-- Toolbar-->
    <div class="toolbar toolbar-bottom">
      <div class="toolbar-inner">
        <a href="/get-started/" class="link"><i class="icon icon-prev"/>Getting Started</a>
        <a href="/responsive-1/" class="link">The Responsive Robot<i class="icon icon-next"/></a>
      </div>
    </div>

    <!-- Scrollable page content-->
    <div class="page-content">
      <div class="block">
        <img src="./static/hello-world-900x200.png" style="width: 100%;" />
      </div>
      <div class="block">
        <p> </p>
        <h3>Simple Commands</h3>
        <p> </p>
        <h3>Repeat Loops</h3>
        <p> </p>
        <h3>Actions</h3>
        <p> </p>
        <h3>Defining Your Data</h3>
        <p> </p>
        <h3>Doing Some Math</h3>
        <p> </p>
        <p> </p>
      </div>
            <div class="block">
              <span style="font-size: 7pt;">©2021 maxamind, llc</span>
            </div>

    </div>
  </div>
`
    }
    ;
}

framework7Component.id = '36069a8cd7';
export default framework7Component;